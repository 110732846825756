/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Text, View, Stack, ArrowRight, Link } from '@az/starc-ui';
import type { ContentStackImageVideoSplit } from '../../interface';
import styles from './CMSImageVideoContent.module.scss';
import { CMSImage } from '../CMSImage/CMSImage';
import { CMSLink } from '../CMSLink/CMSLink';

type Props = {
  content: NonNullable<ContentStackImageVideoSplit['split_content']>[number];
};

export const CMSImageVideoContent = ({ content }: Props) => {
  const { video, image, heading, description, ...optionalProps } = content;
  const ctaLink = 'link_cta' in optionalProps ? optionalProps.link_cta : undefined;
  const imageTargetLink =
    'image_target_link' in optionalProps ? optionalProps.image_target_link : undefined;
  const posterImg = video?.poster_image
    ? video.poster_image
    : '/images/video-default-poster-gray.svg';

  return (
    <View className={styles.container}>
      {image?.image_url ? (
        imageTargetLink ? (
          <CMSLink to={imageTargetLink}>
            <CMSImage image={image} />
          </CMSLink>
        ) : (
          <CMSImage image={image} />
        )
      ) : video?.video_url ? (
        <video
          controls
          src={video.video_url}
          width="100%"
          disablePictureInPicture={false}
          poster={posterImg}
        />
      ) : null}
      <Stack gap={1}>
        {heading && (
          <Text className={styles.heading} size="100" weight="bold">
            {heading}
          </Text>
        )}
        {description && (
          <Text className={styles.description} size="087">
            {description}
          </Text>
        )}
      </Stack>
      {ctaLink?.href && ctaLink.title && (
        <Link
          href={ctaLink.href}
          endIcon={ArrowRight}
          className={styles.linkButton}
          variant="plain"
        >
          {ctaLink.title}
        </Link>
      )}
    </View>
  );
};
