/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ContentStackImageContent } from '../interface/content-types';

export const isValidCMSImageContent = (content: ContentStackImageContent) => {
  return Boolean(content?.image?.image_url || content.description || content.heading);
};
