/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useContext, useState } from 'react';
import type { ContentStackBannerCta, ContentStackButton, SizePerDevice } from '../../interface';
import { Grid } from '@/components/Grid';
import { CMSImage } from '../CMSImage/CMSImage';
import styles from './CMSBannerCTA.module.scss';
import { Legal } from '@/components/AZCustomComponent/Legal';
import { useStreamLineSignIn } from '@/hooks/useStreamLineSignIn';
import { mapContentstackLegalValues } from '@/utils/mapContentstackLegalValues';
import SignInModal from '@/components/SignInModal';
import type { TabIndexes } from '@/components/SignInModal/interface';
import SignInModalV2 from '@/components/SignInModalV2';
import { Button } from '@az/starc-ui';
import { SignInFlowTypeContext } from '@/components/SignInFlowTypeContext';
import { SearchStoreModal } from '@/components/Store/StoreModal/SearchStoreModal';
import { LinkRewardsContext } from '@/components/LinkRewardsContext';
import actionConstants from '@/types/action';
import { useMyProfile } from '@/features/myProfile/api/getMyProfile';

type Props = {
  content: ContentStackBannerCta;
  priority?: boolean;
};

const sizePerDevice: SizePerDevice = {
  mobile: {
    width: 576,
    height: 1432,
  },
  tablet: {
    width: 1376,
    height: 510,
  },
  desktop: {
    width: 2200,
    height: 574,
  },
};

export function CMSBannerCTA({ content, priority }: Props) {
  const { setModalReadyStatus, showRewardSigninView } = useContext(LinkRewardsContext);
  const { image, legal, button, main_image_position } = content;
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const toggleStoreModal = () => {
    setStoreModalOpen((s) => !s);
  };
  const { data: userData } = useMyProfile();

  const isSignInV2Enabled = useStreamLineSignIn();

  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<TabIndexes>(0);
  const { setFlowTypeFn, setIsSignInModalOpened } = useContext(SignInFlowTypeContext);

  const handleStandaloneRewards = (id: string) => {
    if (id.includes('Join')) {
      showRewardSigninView(true);
      setModalReadyStatus(actionConstants.SIGN_ME_UP_FORM);
      return;
    }

    showRewardSigninView(true);
    setModalReadyStatus(actionConstants.LINK_REWARDS_ACC);
  };

  const handleModalOpen = (id: string) => {
    if (userData?.userType === 4) {
      return handleStandaloneRewards(id);
    }
    if (id === '##search-store-modal') {
      toggleStoreModal();
      return;
    }
    if (isSignInV2Enabled) {
      setFlowTypeFn('login');
    }
    setIsSignInModalOpened(true);
    setActiveTab(id === '##sign_in' ? 0 : 1);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setIsSignInModalOpened(false);
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        justify={main_image_position === 'Left' ? 'flex-end' : 'flex-start'}
        className={styles.container}
      >
        {image && (
          <div className={styles.image}>
            <CMSImage
              image={image}
              priority={priority}
              sizePerDevice={sizePerDevice}
              className={styles.responsiveImage}
            />
          </div>
        )}
        <Grid container spacing={0} alignItems="center" className={styles.contentContainer}>
          <Grid container spacing={0} justify="center" className={styles.buttonsContainer}>
            {/* for some reason button has ContentStackButton type instead of ContentStackButton[] */}
            {(button as unknown as ContentStackButton[]).map(({ theme, link }, i) => (
              <Grid key={i} item className={styles.buttonWrapper}>
                {link && (
                  <Button
                    variant={
                      theme
                        ? (theme.toLowerCase() as Lowercase<NonNullable<typeof theme>>)
                        : undefined
                    }
                    onClick={() => handleModalOpen(link.href)}
                  >
                    {link.title}
                  </Button>
                )}
              </Grid>
            ))}
          </Grid>
          <Grid item>{legal && <Legal content={mapContentstackLegalValues(legal)} />}</Grid>
        </Grid>
        {isSignInV2Enabled ? (
          <SignInModalV2
            isOpen={open}
            onClose={handleModalClose}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        ) : (
          <SignInModal
            isOpen={open}
            onClose={handleModalClose}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </Grid>
      {storeModalOpen ? <SearchStoreModal closeStoreModal={toggleStoreModal} /> : null}
    </>
  );
}
