/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { type ContentStackVideoContent } from '../../interface';
import { CMSMediaContent } from '../CMSMediaContent/CMSMediaContent';

type Props = {
  content: ContentStackVideoContent;
  preload?: 'none' | 'metadata' | 'auto';
};

export function CMSVideoContent({ content, preload = 'auto' }: Props) {
  const videoUri = content.video_url?.video_url;
  const posterImg = content.video_url?.poster_image
    ? content.video_url.poster_image
    : '/images/video-default-poster-gray.svg';

  if (!videoUri) {
    return null;
  }

  const options = {
    button: content.button,
    description: content.description,
    direction: content.video_direction,
    heading: content.heading,
    heading_underline: content.heading_underline,
  };
  return (
    <CMSMediaContent
      options={options}
      mediaElement={
        <video preload={preload} controls src={videoUri} width="100%" poster={posterImg} />
      }
    />
  );
}
